var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("main", [
    _c("nav", { attrs: { "aria-label": "breadcrumb" } }, [
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              { attrs: { id: "'breadcrumb0", to: { name: "Transfer" } } },
              [
                _c("span", { attrs: { to: "" } }, [
                  _vm._v(_vm._s(_vm.$t("REPORT.REPORT")))
                ])
              ]
            )
          ],
          1
        ),
        _c("li", { staticClass: "breadcrumb-item" }, [
          _c("span", [_vm._v(_vm._s(_vm.$t("REPORT.REPORT_WL")))])
        ])
      ])
    ]),
    _c("div", [
      _c("h1", [_vm._v(_vm._s(_vm.$t("REPORT.REPORT_WL")))]),
      _c(
        "div",
        {
          staticClass: "btn-toolbar section-group mb-2",
          attrs: { role: "toolbar" }
        },
        [
          _c("div", { staticClass: "col-md-12 row" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "label",
                {
                  staticClass: "col-form-label bold text-right",
                  staticStyle: { width: "6rem" },
                  attrs: { for: "date" }
                },
                [_vm._v(" " + _vm._s(_vm.$t("FILTER.LOGIN_NAME")) + " : ")]
              ),
              _c("div", { staticClass: "px-4" }, [
                _c(
                  "div",
                  { staticClass: "input-group flex-nowrap clearable" },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filterUserId,
                          expression: "filterUserId"
                        }
                      ],
                      staticClass: "form-control",
                      staticStyle: { width: "250px" },
                      attrs: {
                        type: "text",
                        name: "loginName",
                        id: "loginName"
                      },
                      domProps: { value: _vm.filterUserId },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.filterUserId = $event.target.value
                        }
                      }
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.filterUserId,
                            expression: "filterUserId"
                          }
                        ],
                        staticClass: "toggle-username field-icon",
                        attrs: { toggle: "#loginName" }
                      },
                      [
                        _c("i", {
                          staticClass: "far fa-times",
                          on: {
                            click: function($event) {
                              _vm.filterUserId = ""
                            }
                          }
                        })
                      ]
                    )
                  ]
                )
              ])
            ])
          ]),
          _c("div", { staticClass: "col-md-12 row mt-4" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "label",
                {
                  staticClass: "col-form-label bold text-right",
                  staticStyle: { width: "6rem" },
                  attrs: { for: "date" }
                },
                [_vm._v(" " + _vm._s(_vm.$t("FILTER.START_DATE")) + " : ")]
              ),
              _c("div", { staticClass: "px-4" }, [
                _c(
                  "div",
                  {
                    staticClass: "input-group flex-nowrap",
                    staticStyle: { width: "220px" }
                  },
                  [
                    _c("date-picker", {
                      attrs: {
                        id: "startDate",
                        type: "datetime",
                        "input-class": "mx-input2",
                        clearable: false,
                        editable: false,
                        format: "YYYY-MM-DD HH:mm"
                      },
                      model: {
                        value: _vm.dateFilter.startDate,
                        callback: function($$v) {
                          _vm.$set(_vm.dateFilter, "startDate", $$v)
                        },
                        expression: "dateFilter.startDate"
                      }
                    })
                  ],
                  1
                )
              ])
            ]),
            _c("div", { staticClass: "row pl-3" }, [
              _c(
                "label",
                {
                  staticClass: "col-form-label bold text-right",
                  staticStyle: { width: "6rem" },
                  attrs: { for: "date" }
                },
                [_vm._v(" " + _vm._s(_vm.$t("FILTER.END_DATE")) + ": ")]
              ),
              _c("div", { staticClass: "flex-1 px-4" }, [
                _c(
                  "div",
                  {
                    staticClass: "input-group flex-nowrap",
                    staticStyle: { width: "220px" }
                  },
                  [
                    _c("date-picker", {
                      attrs: {
                        id: "toDate",
                        type: "datetime",
                        "input-class": "mx-input2",
                        clearable: false,
                        editable: false,
                        format: "YYYY-MM-DD HH:mm"
                      },
                      on: { pick: _vm.handlePick },
                      model: {
                        value: _vm.dateFilter.endDate,
                        callback: function($$v) {
                          _vm.$set(_vm.dateFilter, "endDate", $$v)
                        },
                        expression: "dateFilter.endDate"
                      }
                    })
                  ],
                  1
                )
              ])
            ]),
            _c(
              "div",
              { staticClass: "row", staticStyle: { "padding-left": "2rem" } },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "overflow",
                    attrs: {
                      to: { name: "WinLose", query: { id: _vm.filterUserId } }
                    }
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-yellow ripple-parent",
                        attrs: { type: "button", id: "search" },
                        on: { click: _vm.filterWinLoseData }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("FILTER.SEARCH")) + " ")]
                    )
                  ]
                )
              ],
              1
            )
          ]),
          _c("div", { staticClass: "col-md-12 row mt-4" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "label",
                {
                  staticClass: "col-form-label bold text-right",
                  staticStyle: { width: "6rem" },
                  attrs: { for: "date" }
                },
                [_vm._v(" " + _vm._s(_vm.$t("FILTER.GAME_TYPE")) + " : ")]
              ),
              _c("div", { staticClass: "px-4" }, [
                _c("div", { staticClass: "form-check-inline" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.gameTypeChecked,
                        expression: "gameTypeChecked"
                      }
                    ],
                    staticClass: "chk",
                    attrs: {
                      type: "checkbox",
                      id: "inlineCheckbox1",
                      value: "ALLIN"
                    },
                    domProps: {
                      checked: Array.isArray(_vm.gameTypeChecked)
                        ? _vm._i(_vm.gameTypeChecked, "ALLIN") > -1
                        : _vm.gameTypeChecked
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.gameTypeChecked,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = "ALLIN",
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.gameTypeChecked = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.gameTypeChecked = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.gameTypeChecked = $$c
                        }
                      }
                    }
                  }),
                  _c(
                    "label",
                    {
                      staticClass: "form-check-label pl-2",
                      attrs: { for: "inlineCheckbox1" }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("GAME_TYPE.ALLIN_OR_FOLD")))]
                  )
                ]),
                _c("div", { staticClass: "form-check-inline" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.gameTypeChecked,
                        expression: "gameTypeChecked"
                      }
                    ],
                    staticClass: "chk",
                    attrs: {
                      type: "checkbox",
                      id: "inlineCheckbox2",
                      value: "HOLDEM"
                    },
                    domProps: {
                      checked: Array.isArray(_vm.gameTypeChecked)
                        ? _vm._i(_vm.gameTypeChecked, "HOLDEM") > -1
                        : _vm.gameTypeChecked
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.gameTypeChecked,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = "HOLDEM",
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.gameTypeChecked = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.gameTypeChecked = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.gameTypeChecked = $$c
                        }
                      }
                    }
                  }),
                  _c(
                    "label",
                    {
                      staticClass: "form-check-label pl-2",
                      attrs: { for: "inlineCheckbox2" }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("GAME_TYPE.HOLDEM")))]
                  )
                ]),
                _c("div", { staticClass: "form-check-inline" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.gameTypeChecked,
                        expression: "gameTypeChecked"
                      }
                    ],
                    staticClass: "chk",
                    attrs: {
                      type: "checkbox",
                      id: "inlineCheckbox3",
                      value: "SIXPLUS"
                    },
                    domProps: {
                      checked: Array.isArray(_vm.gameTypeChecked)
                        ? _vm._i(_vm.gameTypeChecked, "SIXPLUS") > -1
                        : _vm.gameTypeChecked
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.gameTypeChecked,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = "SIXPLUS",
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.gameTypeChecked = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.gameTypeChecked = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.gameTypeChecked = $$c
                        }
                      }
                    }
                  }),
                  _c(
                    "label",
                    {
                      staticClass: "form-check-label pl-2",
                      attrs: { for: "inlineCheckbox3" }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("GAME_TYPE.SIX_PLUS_HOLDEM")))]
                  )
                ]),
                _c("div", { staticClass: "form-check-inline" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.gameTypeChecked,
                        expression: "gameTypeChecked"
                      }
                    ],
                    staticClass: "chk",
                    attrs: {
                      type: "checkbox",
                      id: "inlineCheckbox4",
                      value: "PLO4"
                    },
                    domProps: {
                      checked: Array.isArray(_vm.gameTypeChecked)
                        ? _vm._i(_vm.gameTypeChecked, "PLO4") > -1
                        : _vm.gameTypeChecked
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.gameTypeChecked,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = "PLO4",
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.gameTypeChecked = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.gameTypeChecked = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.gameTypeChecked = $$c
                        }
                      }
                    }
                  }),
                  _c(
                    "label",
                    {
                      staticClass: "form-check-label pl-2",
                      attrs: { for: "inlineCheckbox4" }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("GAME_TYPE.PLO4")))]
                  )
                ]),
                _c("div", { staticClass: "form-check-inline" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.gameTypeChecked,
                        expression: "gameTypeChecked"
                      }
                    ],
                    staticClass: "chk",
                    attrs: {
                      type: "checkbox",
                      id: "inlineCheckbox5",
                      value: "PLO5"
                    },
                    domProps: {
                      checked: Array.isArray(_vm.gameTypeChecked)
                        ? _vm._i(_vm.gameTypeChecked, "PLO5") > -1
                        : _vm.gameTypeChecked
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.gameTypeChecked,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = "PLO5",
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.gameTypeChecked = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.gameTypeChecked = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.gameTypeChecked = $$c
                        }
                      }
                    }
                  }),
                  _c(
                    "label",
                    {
                      staticClass: "form-check-label pl-2",
                      attrs: { for: "inlineCheckbox5" }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("GAME_TYPE.PLO5")) + " ")]
                  )
                ]),
                _c("div", { staticClass: "form-check-inline" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.gameTypeChecked,
                        expression: "gameTypeChecked"
                      }
                    ],
                    staticClass: "chk",
                    attrs: {
                      type: "checkbox",
                      id: "inlineCheckbox6",
                      value: "TOURNAMENT"
                    },
                    domProps: {
                      checked: Array.isArray(_vm.gameTypeChecked)
                        ? _vm._i(_vm.gameTypeChecked, "TOURNAMENT") > -1
                        : _vm.gameTypeChecked
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.gameTypeChecked,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = "TOURNAMENT",
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.gameTypeChecked = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.gameTypeChecked = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.gameTypeChecked = $$c
                        }
                      }
                    }
                  }),
                  _c(
                    "label",
                    {
                      staticClass: "form-check-label pl-2",
                      attrs: { for: "inlineCheckbox6" }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("GAME_TYPE.TOURNAMENT")) + " ")]
                  )
                ])
              ])
            ])
          ])
        ]
      ),
      _c(
        "div",
        { staticClass: "btn-toolbar my-4", attrs: { role: "toolbar" } },
        [
          _c("div", { staticClass: "btn-group mr-auto" }, [
            _c(
              "div",
              {
                staticClass: "btn-group btn-group-green mr-3",
                attrs: { "data-toggle": "buttons" }
              },
              [
                _c(
                  "label",
                  {
                    staticClass:
                      "btn btn-white border z-depth-0 form-check-label waves-effect waves-light"
                  },
                  [
                    _c("input", {
                      staticClass: "form-check-input",
                      attrs: {
                        type: "radio",
                        name: "options",
                        id: "option1",
                        autocomplete: "off"
                      },
                      on: {
                        click: function($event) {
                          return _vm.selectDate("option1")
                        }
                      }
                    }),
                    _c("span", { attrs: { "set-lan": "text:Previous" } }, [
                      _vm._v(_vm._s(_vm.$t("FILTER.PREVIOUS")))
                    ])
                  ]
                ),
                _c(
                  "label",
                  {
                    staticClass:
                      "btn btn-white border z-depth-0 form-check-label active waves-effect waves-light"
                  },
                  [
                    _c("input", {
                      staticClass: "form-check-input",
                      attrs: {
                        type: "radio",
                        name: "options",
                        id: "option2",
                        autocomplete: "off",
                        checked: ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.selectDate("option2")
                        }
                      }
                    }),
                    _c("span", { attrs: { "set-lan": "text:Today" } }, [
                      _vm._v(_vm._s(_vm.$t("FILTER.TODAY")))
                    ])
                  ]
                ),
                _c(
                  "label",
                  {
                    staticClass:
                      "btn btn-white border z-depth-0 form-check-label waves-effect waves-light"
                  },
                  [
                    _c("input", {
                      staticClass: "form-check-input",
                      attrs: {
                        type: "radio",
                        name: "options",
                        id: "option3",
                        autocomplete: "off"
                      },
                      on: {
                        click: function($event) {
                          return _vm.selectDate("option3")
                        }
                      }
                    }),
                    _c("span", { attrs: { "set-lan": "text:Yesterday" } }, [
                      _vm._v(" " + _vm._s(_vm.$t("FILTER.YESTERDAY")) + " ")
                    ])
                  ]
                ),
                _c(
                  "label",
                  {
                    staticClass:
                      "btn btn-white border z-depth-0 form-check-label waves-effect waves-light"
                  },
                  [
                    _c("input", {
                      staticClass: "form-check-input",
                      attrs: {
                        type: "radio",
                        name: "options",
                        id: "option4",
                        autocomplete: "off"
                      },
                      on: {
                        click: function($event) {
                          return _vm.selectDate("option4")
                        }
                      }
                    }),
                    _c("span", { attrs: { "set-lan": "text:This week" } }, [
                      _vm._v(" " + _vm._s(_vm.$t("FILTER.THIS_WEEK")) + " ")
                    ])
                  ]
                ),
                _c(
                  "label",
                  {
                    staticClass:
                      "btn btn-white border z-depth-0 form-check-label waves-effect waves-light"
                  },
                  [
                    _c("input", {
                      staticClass: "form-check-input",
                      attrs: {
                        type: "radio",
                        name: "options",
                        id: "option5",
                        autocomplete: "off"
                      },
                      on: {
                        click: function($event) {
                          return _vm.selectDate("option5")
                        }
                      }
                    }),
                    _c("span", { attrs: { "set-lan": "text:Last week" } }, [
                      _vm._v(" " + _vm._s(_vm.$t("FILTER.LAST_WEEK")) + " ")
                    ])
                  ]
                ),
                _c(
                  "label",
                  {
                    staticClass:
                      "btn btn-white border z-depth-0 form-check-label waves-effect waves-light"
                  },
                  [
                    _c("input", {
                      staticClass: "form-check-input",
                      attrs: {
                        type: "radio",
                        name: "options",
                        id: "option6",
                        autocomplete: "off"
                      },
                      on: {
                        click: function($event) {
                          return _vm.selectDate("option6")
                        }
                      }
                    }),
                    _c("span", { attrs: { "set-lan": "text:This month" } }, [
                      _vm._v(" " + _vm._s(_vm.$t("FILTER.THIS_MONTH")) + " ")
                    ])
                  ]
                ),
                _c(
                  "label",
                  {
                    staticClass:
                      "btn btn-white border z-depth-0 form-check-label waves-effect waves-light"
                  },
                  [
                    _c("input", {
                      staticClass: "form-check-input",
                      attrs: {
                        type: "radio",
                        name: "options",
                        id: "option7",
                        autocomplete: "off"
                      },
                      on: {
                        click: function($event) {
                          return _vm.selectDate("option7")
                        }
                      }
                    }),
                    _c("span", { attrs: { "set-lan": "text:Last month" } }, [
                      _vm._v(" " + _vm._s(_vm.$t("FILTER.LAST_MONTH")) + " ")
                    ])
                  ]
                ),
                _c(
                  "label",
                  {
                    staticClass:
                      "btn btn-white border z-depth-0 form-check-label waves-effect waves-light"
                  },
                  [
                    _c("input", {
                      staticClass: "form-check-input",
                      attrs: {
                        type: "radio",
                        name: "options",
                        id: "option8",
                        autocomplete: "off"
                      },
                      on: {
                        click: function($event) {
                          return _vm.selectDate("option8")
                        }
                      }
                    }),
                    _c("span", { attrs: { "set-lan": "text:Next" } }, [
                      _vm._v(" " + _vm._s(_vm.$t("FILTER.NEXT")) + " ")
                    ])
                  ]
                )
              ]
            )
          ])
        ]
      ),
      _vm.dataLoading
        ? _c("div", { staticClass: "row" }, [
            _vm.roleNameTable !== "Member"
              ? _c("div", { staticClass: "col-12" }, [
                  _c("div", { staticClass: "row mt-2" }, [
                    _c("div", { staticClass: "col-sm-6" }, [
                      _c("nav", { attrs: { "aria-label": "breadcrumb" } }, [
                        _c(
                          "ol",
                          { staticClass: "breadcrumb" },
                          _vm._l(_vm.$store.state.reportPages, function(
                            t,
                            index
                          ) {
                            return _c(
                              "li",
                              { key: index, staticClass: "breadcrumb-item" },
                              [
                                index == 0
                                  ? _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          id: "breadcrumb" + index,
                                          to: { name: "WinLose" }
                                        }
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "linkNav bold",
                                            staticStyle: {
                                              "text-decoration": "underline"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.goBack(t.id, t.role)
                                              }
                                            }
                                          },
                                          [_vm._v(" " + _vm._s(t.id) + " ")]
                                        )
                                      ]
                                    )
                                  : index > 0 &&
                                    index <
                                      _vm.$store.state.reportPages.length - 1
                                  ? _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          id: "breadcrumb" + index,
                                          to: {
                                            name: "WinLose",
                                            query: { id: t.id }
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "linkNav bold",
                                            staticStyle: {
                                              "text-decoration": "underline"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.goBack(t.id, t.role)
                                              }
                                            }
                                          },
                                          [_vm._v(" " + _vm._s(t.id) + " ")]
                                        )
                                      ]
                                    )
                                  : _c("a", [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "bold",
                                          staticStyle: {
                                            color: "#cfa137",
                                            "text-decoration": "underline"
                                          },
                                          attrs: { id: "breadcrumb" + index }
                                        },
                                        [_vm._v(" " + _vm._s(t.id) + " ")]
                                      )
                                    ])
                              ],
                              1
                            )
                          }),
                          0
                        )
                      ])
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-sm-6" },
                      [
                        _vm.paginateShow && _vm.roleNameTable !== "Company"
                          ? _c("paginate", {
                              attrs: {
                                "page-count": _vm.pageSize,
                                "page-range": 3,
                                "prev-text": "<<",
                                "next-text": ">>",
                                "click-handler": _vm.clickCallback,
                                "container-class": "pagination float-right",
                                "prev-class": "inline",
                                "next-class": "inline",
                                "page-class": "inline"
                              },
                              model: {
                                value: _vm.paginate.page,
                                callback: function($$v) {
                                  _vm.$set(_vm.paginate, "page", $$v)
                                },
                                expression: "paginate.page"
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "table-responsive" }, [
                    _c(
                      "table",
                      { staticClass: "table table-bordered table-hover" },
                      [
                        _c("thead", [
                          _c("tr", [
                            _c(
                              "th",
                              {
                                staticClass: "col-text-padding",
                                staticStyle: {
                                  "text-align": "center",
                                  width: "1%"
                                },
                                attrs: { rowspan: "2" }
                              },
                              [_vm._v(" " + _vm._s(_vm.$t("REPORT.NO")) + " ")]
                            ),
                            _c(
                              "th",
                              {
                                staticClass: "col-text-padding",
                                staticStyle: {
                                  "text-align": "center",
                                  width: "10%"
                                },
                                attrs: { rowspan: "2" }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("REPORT.LOGIN_NAME")) +
                                    " "
                                )
                              ]
                            ),
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  "text-align": "center",
                                  width: "7%"
                                },
                                attrs: { rowspan: "2" }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("REPORT.VALID_AMOUNT")) +
                                    " "
                                )
                              ]
                            ),
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  "text-align": "center",
                                  width: "7%"
                                },
                                attrs: { rowspan: "2" }
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("REPORT.TURN_OVER")) + " "
                                )
                              ]
                            ),
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  "text-align": "center",
                                  width: "7%"
                                },
                                attrs: { rowspan: "2" }
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("REPORT.VALID_POT")) + " "
                                )
                              ]
                            ),
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  "text-align": "center",
                                  width: "6%"
                                },
                                attrs: { rowspan: "2" }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("REPORT.BET_COUNT_HEADER")) +
                                    " "
                                )
                              ]
                            ),
                            _c(
                              "th",
                              {
                                staticClass: "col-text-padding",
                                staticStyle: {
                                  "text-align": "center",
                                  width: "5%"
                                },
                                attrs: { rowspan: "2" }
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("REPORT.MEMBER_WL")) + " "
                                ),
                                _c("br"),
                                _vm._v(" (100%) ")
                              ]
                            ),
                            _c(
                              "th",
                              {
                                staticStyle: { "text-align": "center" },
                                attrs: { colspan: _vm.getReportColspan() }
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("REPORT.MEMBER")) + " "
                                )
                              ]
                            ),
                            _vm.roleName != "Company"
                              ? _c(
                                  "th",
                                  {
                                    staticStyle: { "text-align": "center" },
                                    attrs: { colspan: _vm.getReportColspan() }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.roleName == "Shareholder"
                                            ? _vm.$t("REPORT.SHAREHOLDER")
                                            : _vm.$t("REPORT.AGENT")
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "th",
                              {
                                staticStyle: { "text-align": "center" },
                                attrs: { colspan: _vm.getReportColspan() }
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("REPORT.COMPANY")) + " "
                                )
                              ]
                            )
                          ]),
                          _c("tr", [
                            _c(
                              "th",
                              {
                                staticClass: "col-text-padding",
                                staticStyle: {
                                  "text-align": "right",
                                  width: "5%"
                                }
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("REPORT.WINLOSE")) + " "
                                )
                              ]
                            ),
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  "text-align": "right",
                                  width: "5%"
                                }
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("REPORT.COMM")) + " "
                                )
                              ]
                            ),
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  "text-align": "right",
                                  width: "5%"
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("REPORT.PRIZE_POOL_THEADER")
                                    ) +
                                    " "
                                )
                              ]
                            ),
                            _vm.columnControlReport.betOnFlop
                              ? _c(
                                  "th",
                                  {
                                    staticStyle: {
                                      "text-align": "right",
                                      width: "5%"
                                    }
                                  },
                                  [_vm._v(" BOF ")]
                                )
                              : _vm._e(),
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  "text-align": "right",
                                  width: "5%"
                                }
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("REPORT.TOTAL")) + " "
                                )
                              ]
                            ),
                            _c(
                              "th",
                              {
                                staticClass: "col-text-padding",
                                staticStyle: {
                                  "text-align": "right",
                                  width: "5%"
                                }
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("REPORT.WINLOSE")) + " "
                                )
                              ]
                            ),
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  "text-align": "right",
                                  width: "5%"
                                }
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("REPORT.COMM")) + " "
                                )
                              ]
                            ),
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  "text-align": "right",
                                  width: "5%"
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("REPORT.PRIZE_POOL_THEADER")
                                    ) +
                                    " "
                                )
                              ]
                            ),
                            _vm.columnControlReport.betOnFlop
                              ? _c(
                                  "th",
                                  {
                                    staticStyle: {
                                      "text-align": "right",
                                      width: "5%"
                                    }
                                  },
                                  [_vm._v(" BOF ")]
                                )
                              : _vm._e(),
                            _c(
                              "th",
                              {
                                staticClass: "col-text-padding",
                                staticStyle: {
                                  "text-align": "right",
                                  width: "5%"
                                }
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("REPORT.TOTAL")) + " "
                                )
                              ]
                            ),
                            _vm.roleName != "Company"
                              ? _c(
                                  "th",
                                  {
                                    staticClass: "col-text-padding",
                                    staticStyle: {
                                      "text-align": "right",
                                      width: "5%"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("REPORT.WINLOSE")) +
                                        " "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm.roleName != "Company"
                              ? _c(
                                  "th",
                                  {
                                    staticStyle: {
                                      "text-align": "right",
                                      width: "5%"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("REPORT.COMM")) + " "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm.roleName != "Company"
                              ? _c(
                                  "th",
                                  {
                                    staticStyle: {
                                      "text-align": "right",
                                      width: "5%"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("REPORT.PRIZE_POOL_THEADER")
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm.roleName != "Company" &&
                            _vm.columnControlReport.betOnFlop
                              ? _c(
                                  "th",
                                  {
                                    staticStyle: {
                                      "text-align": "right",
                                      width: "5%"
                                    }
                                  },
                                  [_vm._v(" BOF ")]
                                )
                              : _vm._e(),
                            _vm.roleName != "Company"
                              ? _c(
                                  "th",
                                  {
                                    staticClass: "col-text-padding",
                                    staticStyle: {
                                      "text-align": "right",
                                      width: "5%"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("REPORT.TOTAL")) + " "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ])
                        ]),
                        !_vm.dataLoading
                          ? _c("tbody", [_vm._m(0)])
                          : _c(
                              "tbody",
                              [
                                _vm.winLoseData.length == 0
                                  ? [
                                      _c("tr", [
                                        _c("td", { attrs: { colspan: "15" } }, [
                                          _vm._v(
                                            _vm._s(_vm.$t("DATA_NOT_FOUND"))
                                          )
                                        ])
                                      ])
                                    ]
                                  : _vm.roleName != "Company"
                                  ? _vm._l(_vm.winLoseData.metadata, function(
                                      r
                                    ) {
                                      return _c(
                                        "tr",
                                        {
                                          key: r.id,
                                          class: {
                                            inactive: r.status === "INACTIVE"
                                          }
                                        },
                                        [
                                          _c("td", [_vm._v(_vm._s(r.no + 1))]),
                                          _c(
                                            "td",
                                            {
                                              staticStyle: {
                                                "text-align": "left"
                                              }
                                            },
                                            [
                                              r.roles == "Member"
                                                ? _c(
                                                    "router-link",
                                                    {
                                                      staticClass: "overflow",
                                                      attrs: {
                                                        to: {
                                                          name: "WinLose",
                                                          query: {
                                                            id:
                                                              r.usernameWithPrefix
                                                          }
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass: "link",
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.getReportById(
                                                                r.usernameWithPrefix,
                                                                r.roles
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                r.usernameWithPrefix
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                : _c(
                                                    "router-link",
                                                    {
                                                      staticClass: "overflow",
                                                      attrs: {
                                                        to: {
                                                          name: "WinLose",
                                                          query: {
                                                            id: r.username
                                                          }
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass: "link",
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.getReportById(
                                                                r.username,
                                                                r.roles
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                r.username
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "td",
                                            {
                                              staticStyle: {
                                                "text-align": "right"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("currency")(
                                                      r.validStake
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            {
                                              staticStyle: {
                                                "text-align": "right"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("currency")(
                                                      r.validTurnOver
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            {
                                              staticStyle: {
                                                "text-align": "right"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("currency")(
                                                      r.validAmountWinPots
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            {
                                              staticStyle: {
                                                "text-align": "right",
                                                color: "#000000"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(r.betCount) + " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            {
                                              staticStyle: {
                                                "text-align": "right"
                                              },
                                              style:
                                                Math.sign(
                                                  r.winlose100PercentOfMember
                                                ) == -1
                                                  ? "color: #d42222"
                                                  : "color: #000000"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("currency")(
                                                      r.winlose100PercentOfMember
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            {
                                              staticStyle: {
                                                "text-align": "right",
                                                background:
                                                  "rgba(255, 249, 224, 0.42)"
                                              },
                                              style:
                                                Math.sign(r.currLevelWinLose) ==
                                                -1
                                                  ? "color: #d42222"
                                                  : "color: #000000"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("currency")(
                                                      r.currLevelWinLose
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            {
                                              staticStyle: {
                                                "text-align": "right",
                                                background:
                                                  "rgba(255, 249, 224, 0.42)"
                                              },
                                              style:
                                                Math.sign(
                                                  r.currLevelCommission
                                                ) == -1
                                                  ? "color: #d42222"
                                                  : "color: #000000"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("currency")(
                                                      r.currLevelCommission
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            {
                                              staticStyle: {
                                                "text-align": "right",
                                                background:
                                                  "rgba(255, 249, 224, 0.42)"
                                              },
                                              style:
                                                Math.sign(
                                                  r.currLevelKeepPrizePoolAmount
                                                ) == -1
                                                  ? "color: #d42222"
                                                  : "color: #000000"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("currency")(
                                                      r.currLevelKeepPrizePoolAmount
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _vm.columnControlReport.betOnFlop
                                            ? _c(
                                                "td",
                                                {
                                                  staticStyle: {
                                                    "text-align": "right",
                                                    background:
                                                      "rgba(255, 249, 224, 0.42)"
                                                  },
                                                  style:
                                                    Math.sign(
                                                      r.currLevelBetOnFlop
                                                    ) == -1
                                                      ? "color: #d42222"
                                                      : "color: #000000"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm._f("currency")(
                                                          r.currLevelBetOnFlop
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "td",
                                            {
                                              staticStyle: {
                                                "text-align": "right",
                                                background:
                                                  "rgba(255, 249, 224, 0.42)"
                                              },
                                              style:
                                                Math.sign(r.currLevelTotal) ==
                                                -1
                                                  ? "color: #d42222"
                                                  : "color: #000000"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("currency")(
                                                      r.currLevelTotal
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            {
                                              staticStyle: {
                                                "text-align": "right",
                                                background:
                                                  "rgba(252, 234, 158, 0.46)"
                                              },
                                              style:
                                                Math.sign(
                                                  r.parentLevelWinLose
                                                ) == -1
                                                  ? "color: #d42222"
                                                  : "color: #000000"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("currency")(
                                                      r.parentLevelWinLose
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            {
                                              staticStyle: {
                                                "text-align": "right",
                                                background:
                                                  "rgba(252, 234, 158, 0.46)"
                                              },
                                              style:
                                                Math.sign(
                                                  r.commissionParent * -1
                                                ) == -1
                                                  ? "color: #d42222"
                                                  : "color: #000000"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("currency")(
                                                      r.commissionParent * -1
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            {
                                              staticStyle: {
                                                "text-align": "right",
                                                background:
                                                  "rgba(255, 249, 224, 0.42)"
                                              },
                                              style:
                                                Math.sign(
                                                  r.keepPrizePoolAmountParent
                                                ) == -1
                                                  ? "color: #d42222"
                                                  : "color: #000000"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("currency")(
                                                      r.keepPrizePoolAmountParent
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _vm.columnControlReport.betOnFlop
                                            ? _c(
                                                "td",
                                                {
                                                  staticStyle: {
                                                    "text-align": "right",
                                                    background:
                                                      "rgba(255, 249, 224, 0.42)"
                                                  },
                                                  style:
                                                    Math.sign(
                                                      r.betOnFlopParent
                                                    ) == -1
                                                      ? "color: #d42222"
                                                      : "color: #000000"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm._f("currency")(
                                                          r.betOnFlopParent
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "td",
                                            {
                                              staticStyle: {
                                                "text-align": "right",
                                                background:
                                                  "rgba(252, 234, 158, 0.46)"
                                              },
                                              style:
                                                Math.sign(r.parentLevelTotal) ==
                                                -1
                                                  ? "color: #d42222"
                                                  : "color: #000000"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("currency")(
                                                      r.parentLevelTotal
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            {
                                              staticStyle: {
                                                "text-align": "right",
                                                background:
                                                  "rgba(255, 208, 99, 0.45)"
                                              },
                                              style:
                                                Math.sign(
                                                  r.grandParentLevelWinLose
                                                ) == -1
                                                  ? "color: #d42222"
                                                  : "color: #000000"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("currency")(
                                                      r.grandParentLevelWinLose
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            {
                                              staticStyle: {
                                                "text-align": "right",
                                                background:
                                                  "rgba(255, 208, 99, 0.45)"
                                              },
                                              style:
                                                Math.sign(
                                                  r.commissionGrandParent * -1
                                                ) == -1
                                                  ? "color: #d42222"
                                                  : "color: #000000"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("currency")(
                                                      r.commissionGrandParent *
                                                        -1
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            {
                                              staticStyle: {
                                                "text-align": "right",
                                                background:
                                                  "rgba(255, 249, 224, 0.42)"
                                              },
                                              style:
                                                Math.sign(
                                                  r.keepPrizePoolAmountGrandParent
                                                ) == -1
                                                  ? "color: #d42222"
                                                  : "color: #000000"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("currency")(
                                                      r.keepPrizePoolAmountGrandParent
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _vm.columnControlReport.betOnFlop
                                            ? _c(
                                                "td",
                                                {
                                                  staticStyle: {
                                                    "text-align": "right",
                                                    background:
                                                      "rgba(255, 249, 224, 0.42)"
                                                  },
                                                  style:
                                                    Math.sign(
                                                      r.betOnFlopGrandParent
                                                    ) == -1
                                                      ? "color: #d42222"
                                                      : "color: #000000"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm._f("currency")(
                                                          r.betOnFlopGrandParent
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "td",
                                            {
                                              staticStyle: {
                                                "text-align": "right",
                                                background:
                                                  "rgba(255, 208, 99, 0.45)"
                                              },
                                              style:
                                                Math.sign(
                                                  r.grandParentLevelTotal
                                                ) == -1
                                                  ? "color: #d42222"
                                                  : "color: #000000"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("currency")(
                                                      r.grandParentLevelTotal
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    })
                                  : _vm._l(_vm.winLoseData.metadata, function(
                                      r
                                    ) {
                                      return _c(
                                        "tr",
                                        {
                                          key: r.id,
                                          class: {
                                            inactive: r.status === "INACTIVE"
                                          }
                                        },
                                        [
                                          _c("td", [_vm._v(_vm._s(r.no + 1))]),
                                          _c(
                                            "td",
                                            {
                                              staticStyle: {
                                                "text-align": "left"
                                              }
                                            },
                                            [
                                              r.roles == "Member"
                                                ? _c(
                                                    "router-link",
                                                    {
                                                      staticClass: "overflow",
                                                      attrs: {
                                                        to: {
                                                          name: "WinLose",
                                                          query: {
                                                            id:
                                                              r.usernameWithPrefix
                                                          }
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass: "link",
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.getReportById(
                                                                r.usernameWithPrefix,
                                                                r.roles
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                r.usernameWithPrefix
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                : _c(
                                                    "router-link",
                                                    {
                                                      staticClass: "overflow",
                                                      attrs: {
                                                        to: {
                                                          name: "WinLose",
                                                          query: {
                                                            id: r.username
                                                          }
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass: "link",
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.getReportById(
                                                                r.username,
                                                                r.roles
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                r.username
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "td",
                                            {
                                              staticStyle: {
                                                "text-align": "right"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("currency")(
                                                      r.validStake
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            {
                                              staticStyle: {
                                                "text-align": "right"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("currency")(
                                                      r.validTurnOver
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            {
                                              staticStyle: {
                                                "text-align": "right"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("currency")(
                                                      r.validAmountWinPots
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            {
                                              staticStyle: {
                                                "text-align": "right",
                                                color: "#000000"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(r.betCount) + " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            {
                                              staticStyle: {
                                                "text-align": "right"
                                              },
                                              style:
                                                Math.sign(
                                                  r.winlose100PercentOfMember
                                                ) == -1
                                                  ? "color: #d42222"
                                                  : "color: #000000"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("currency")(
                                                      r.winlose100PercentOfMember
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            {
                                              staticStyle: {
                                                "text-align": "right",
                                                background:
                                                  "rgba(255, 249, 224, 0.42)"
                                              },
                                              style:
                                                Math.sign(r.currLevelWinLose) ==
                                                -1
                                                  ? "color: #d42222"
                                                  : "color: #000000"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("currency")(
                                                      r.currLevelWinLose
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            {
                                              staticStyle: {
                                                "text-align": "right",
                                                background:
                                                  "rgba(255, 249, 224, 0.42)"
                                              },
                                              style:
                                                Math.sign(
                                                  r.currLevelCommission
                                                ) == -1
                                                  ? "color: #d42222"
                                                  : "color: #000000"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("currency")(
                                                      r.currLevelCommission
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            {
                                              staticStyle: {
                                                "text-align": "right",
                                                background:
                                                  "rgba(255, 249, 224, 0.42)"
                                              },
                                              style:
                                                Math.sign(
                                                  r.currLevelKeepPrizePoolAmount
                                                ) == -1
                                                  ? "color: #d42222"
                                                  : "color: #000000"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("currency")(
                                                      r.currLevelKeepPrizePoolAmount
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _vm.columnControlReport.betOnFlop
                                            ? _c(
                                                "td",
                                                {
                                                  staticStyle: {
                                                    "text-align": "right",
                                                    background:
                                                      "rgba(255, 249, 224, 0.42)"
                                                  },
                                                  style:
                                                    Math.sign(
                                                      r.currLevelBetOnFlop
                                                    ) == -1
                                                      ? "color: #d42222"
                                                      : "color: #000000"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm._f("currency")(
                                                          r.currLevelBetOnFlop
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "td",
                                            {
                                              staticStyle: {
                                                "text-align": "right",
                                                background:
                                                  "rgba(255, 249, 224, 0.42)"
                                              },
                                              style:
                                                Math.sign(r.currLevelTotal) ==
                                                -1
                                                  ? "color: #d42222"
                                                  : "color: #000000"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("currency")(
                                                      r.currLevelTotal
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            {
                                              staticStyle: {
                                                "text-align": "right",
                                                background:
                                                  "rgba(252, 234, 158, 0.46)"
                                              },
                                              style:
                                                Math.sign(
                                                  r.parentLevelWinLose
                                                ) == -1
                                                  ? "color: #d42222"
                                                  : "color: #000000"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("currency")(
                                                      r.parentLevelWinLose
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            {
                                              staticStyle: {
                                                "text-align": "right",
                                                background:
                                                  "rgba(252, 234, 158, 0.46)"
                                              },
                                              style:
                                                Math.sign(
                                                  r.commissionParent * -1
                                                ) == -1
                                                  ? "color: #d42222"
                                                  : "color: #000000"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("currency")(
                                                      r.commissionParent * -1
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            {
                                              staticStyle: {
                                                "text-align": "right",
                                                background:
                                                  "rgba(255, 249, 224, 0.42)"
                                              },
                                              style:
                                                Math.sign(
                                                  r.keepPrizePoolAmountParent
                                                ) == -1
                                                  ? "color: #d42222"
                                                  : "color: #000000"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("currency")(
                                                      r.keepPrizePoolAmountParent
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _vm.columnControlReport.betOnFlop
                                            ? _c(
                                                "td",
                                                {
                                                  staticStyle: {
                                                    "text-align": "right",
                                                    background:
                                                      "rgba(255, 249, 224, 0.42)"
                                                  },
                                                  style:
                                                    Math.sign(
                                                      r.betOnFlopParent
                                                    ) == -1
                                                      ? "color: #d42222"
                                                      : "color: #000000"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm._f("currency")(
                                                          r.betOnFlopParent
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "td",
                                            {
                                              staticStyle: {
                                                "text-align": "right",
                                                background:
                                                  "rgba(252, 234, 158, 0.46)"
                                              },
                                              style:
                                                Math.sign(r.parentLevelTotal) ==
                                                -1
                                                  ? "color: #d42222"
                                                  : "color: #000000"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("currency")(
                                                      r.parentLevelTotal
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    })
                              ],
                              2
                            ),
                        _vm.roleName != "Company"
                          ? _c("tfoot", [
                              _c("tr", { staticClass: "footer" }, [
                                _c("td"),
                                _c(
                                  "td",
                                  { staticStyle: { "text-align": "right" } },
                                  [_vm._v("Total :")]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticStyle: { "text-align": "right" },
                                    style:
                                      Math.sign(_vm.dataSummary.validStake) ==
                                      -1
                                        ? "color: #d42222"
                                        : "color: #000000"
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("currency")(
                                            _vm.dataSummary.validStake
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                ),
                                _c(
                                  "td",
                                  { staticStyle: { "text-align": "right" } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("currency")(
                                            _vm.dataSummary.validTurnOver
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                ),
                                _c(
                                  "td",
                                  { staticStyle: { "text-align": "right" } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("currency")(
                                            _vm.dataSummary.validAmountWinPots
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      "text-align": "right",
                                      color: "#000000"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.dataSummary.betCount) +
                                        " "
                                    )
                                  ]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticStyle: { "text-align": "right" },
                                    style:
                                      Math.sign(
                                        _vm.dataSummary
                                          .winlose100PercentOfMember
                                      ) == -1
                                        ? "color: #d42222"
                                        : "color: #000000"
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("currency")(
                                            _vm.dataSummary
                                              .winlose100PercentOfMember
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticStyle: { "text-align": "right" },
                                    style:
                                      Math.sign(
                                        _vm.dataSummary.currLevelWinLose
                                      ) == -1
                                        ? "color: #d42222"
                                        : "color: #000000"
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("currency")(
                                            _vm.dataSummary.currLevelWinLose
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticStyle: { "text-align": "right" },
                                    style:
                                      Math.sign(
                                        _vm.dataSummary
                                          .commissionAllParentOfChild
                                      ) == -1
                                        ? "color: #d42222"
                                        : "color: #000000"
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("currency")(
                                            _vm.dataSummary
                                              .commissionAllParentOfChild
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticStyle: { "text-align": "right" },
                                    style:
                                      Math.sign(
                                        _vm.dataSummary
                                          .keepPrizePoolAmountAllParentOfChild
                                      ) == -1
                                        ? "color: #d42222"
                                        : "color: #000000"
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("currency")(
                                            _vm.dataSummary
                                              .keepPrizePoolAmountAllParentOfChild
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                ),
                                _vm.columnControlReport.betOnFlop
                                  ? _c(
                                      "td",
                                      {
                                        staticStyle: { "text-align": "right" },
                                        style:
                                          Math.sign(
                                            _vm.dataSummary
                                              .betOnFlopAllParentOfChild
                                          ) == -1
                                            ? "color: #d42222"
                                            : "color: #000000"
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("currency")(
                                                _vm.dataSummary
                                                  .betOnFlopAllParentOfChild
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "td",
                                  {
                                    staticStyle: { "text-align": "right" },
                                    style:
                                      Math.sign(
                                        _vm.dataSummary.currLevelTotal
                                      ) == -1
                                        ? "color: #d42222"
                                        : "color: #000000"
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("currency")(
                                            _vm.dataSummary.currLevelTotal
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticStyle: { "text-align": "right" },
                                    style:
                                      Math.sign(
                                        _vm.dataSummary.parentLevelWinLose
                                      ) == -1
                                        ? "color: #d42222"
                                        : "color: #000000"
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("currency")(
                                            _vm.dataSummary.parentLevelWinLose
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticStyle: { "text-align": "right" },
                                    style:
                                      Math.sign(
                                        _vm.dataSummary.commissionCurrent * -1
                                      ) == -1
                                        ? "color: #d42222"
                                        : "color: #000000"
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("currency")(
                                            _vm.dataSummary.commissionCurrent *
                                              -1
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticStyle: { "text-align": "right" },
                                    style:
                                      Math.sign(
                                        _vm.dataSummary
                                          .keepPrizePoolAmountCurrent
                                      ) == -1
                                        ? "color: #d42222"
                                        : "color: #000000"
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("currency")(
                                            _vm.dataSummary
                                              .keepPrizePoolAmountCurrent
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                ),
                                _vm.columnControlReport.betOnFlop
                                  ? _c(
                                      "td",
                                      {
                                        staticStyle: { "text-align": "right" },
                                        style:
                                          Math.sign(
                                            _vm.dataSummary.betOnFlopCurrent
                                          ) == -1
                                            ? "color: #d42222"
                                            : "color: #000000"
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("currency")(
                                                _vm.dataSummary.betOnFlopCurrent
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "td",
                                  {
                                    staticStyle: { "text-align": "right" },
                                    style:
                                      Math.sign(_vm.dataSummary.parentTotal) ==
                                      -1
                                        ? "color: #d42222"
                                        : "color: #000000"
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("currency")(
                                            _vm.dataSummary.parentTotal
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticStyle: { "text-align": "right" },
                                    style:
                                      Math.sign(
                                        _vm.dataSummary.grandParentLevelWinLose
                                      ) == -1
                                        ? "color: #d42222"
                                        : "color: #000000"
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("currency")(
                                            _vm.dataSummary
                                              .grandParentLevelWinLose
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticStyle: { "text-align": "right" },
                                    style:
                                      Math.sign(
                                        _vm.dataSummary
                                          .grandParentLevelCommission
                                      ) == -1
                                        ? "color: #d42222"
                                        : "color: #000000"
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("currency")(
                                            _vm.dataSummary
                                              .grandParentLevelCommission
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticStyle: { "text-align": "right" },
                                    style:
                                      Math.sign(
                                        _vm.dataSummary
                                          .grandParentLevelkeepPrizePoolAmount
                                      ) == -1
                                        ? "color: #d42222"
                                        : "color: #000000"
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("currency")(
                                            _vm.dataSummary
                                              .grandParentLevelkeepPrizePoolAmount
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                ),
                                _vm.columnControlReport.betOnFlop
                                  ? _c(
                                      "td",
                                      {
                                        staticStyle: { "text-align": "right" },
                                        style:
                                          Math.sign(
                                            _vm.dataSummary
                                              .grandParentLevelBetOnFlop
                                          ) == -1
                                            ? "color: #d42222"
                                            : "color: #000000"
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("currency")(
                                                _vm.dataSummary
                                                  .grandParentLevelBetOnFlop
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "td",
                                  {
                                    staticStyle: { "text-align": "right" },
                                    style:
                                      Math.sign(
                                        _vm.dataSummary.grandParentLevelTotal
                                      ) == -1
                                        ? "color: #d42222"
                                        : "color: #000000"
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("currency")(
                                            _vm.dataSummary
                                              .grandParentLevelTotal
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ])
                            ])
                          : _c("tfoot", [
                              _c("tr", { staticClass: "footer" }, [
                                _c("td"),
                                _c(
                                  "td",
                                  { staticStyle: { "text-align": "right" } },
                                  [_vm._v(" Total : ")]
                                ),
                                _c(
                                  "td",
                                  { staticStyle: { "text-align": "right" } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("currency")(
                                            _vm.dataSummary.validStake
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                ),
                                _c(
                                  "td",
                                  { staticStyle: { "text-align": "right" } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("currency")(
                                            _vm.dataSummary.validTurnOver
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                ),
                                _c(
                                  "td",
                                  { staticStyle: { "text-align": "right" } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("currency")(
                                            _vm.dataSummary.validAmountWinPots
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      "text-align": "right",
                                      color: "#000000"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.dataSummary.betCount) +
                                        " "
                                    )
                                  ]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticStyle: { "text-align": "right" },
                                    style:
                                      Math.sign(
                                        _vm.dataSummary
                                          .winlose100PercentOfMember
                                      ) == -1
                                        ? "color: #d42222"
                                        : "color: #000000"
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("currency")(
                                            _vm.dataSummary
                                              .winlose100PercentOfMember
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticStyle: { "text-align": "right" },
                                    style:
                                      Math.sign(
                                        _vm.dataSummary.currLevelWinLose
                                      ) == -1
                                        ? "color: #d42222"
                                        : "color: #000000"
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("currency")(
                                            _vm.dataSummary.currLevelWinLose
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticStyle: { "text-align": "right" },
                                    style:
                                      Math.sign(
                                        _vm.dataSummary
                                          .commissionAllParentOfChild
                                      ) == -1
                                        ? "color: #d42222"
                                        : "color: #000000"
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("currency")(
                                            _vm.dataSummary
                                              .commissionAllParentOfChild
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticStyle: { "text-align": "right" },
                                    style:
                                      Math.sign(
                                        _vm.dataSummary
                                          .keepPrizePoolAmountAllParentOfChild
                                      ) == -1
                                        ? "color: #d42222"
                                        : "color: #000000"
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("currency")(
                                            _vm.dataSummary
                                              .keepPrizePoolAmountAllParentOfChild
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                ),
                                _vm.columnControlReport.betOnFlop
                                  ? _c(
                                      "td",
                                      {
                                        staticStyle: { "text-align": "right" },
                                        style:
                                          Math.sign(
                                            _vm.dataSummary
                                              .betOnFlopAllParentOfChild
                                          ) == -1
                                            ? "color: #d42222"
                                            : "color: #000000"
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("currency")(
                                                _vm.dataSummary
                                                  .betOnFlopAllParentOfChild
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "td",
                                  {
                                    staticStyle: { "text-align": "right" },
                                    style:
                                      Math.sign(
                                        _vm.dataSummary.currLevelTotal
                                      ) == -1
                                        ? "color: #d42222"
                                        : "color: #000000"
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("currency")(
                                            _vm.dataSummary.currLevelTotal
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticStyle: { "text-align": "right" },
                                    style:
                                      Math.sign(
                                        _vm.dataSummary.parentLevelWinLose
                                      ) == -1
                                        ? "color: #d42222"
                                        : "color: #000000"
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("currency")(
                                            _vm.dataSummary.parentLevelWinLose
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticStyle: { "text-align": "right" },
                                    style:
                                      Math.sign(
                                        _vm.dataSummary.commissionCurrent * -1
                                      ) == -1
                                        ? "color: #d42222"
                                        : "color: #000000"
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("currency")(
                                            _vm.dataSummary.commissionCurrent *
                                              -1
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticStyle: { "text-align": "right" },
                                    style:
                                      Math.sign(
                                        _vm.dataSummary
                                          .keepPrizePoolAmountCurrent
                                      ) == -1
                                        ? "color: #d42222"
                                        : "color: #000000"
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("currency")(
                                            _vm.dataSummary
                                              .keepPrizePoolAmountCurrent
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                ),
                                _vm.columnControlReport.betOnFlop
                                  ? _c(
                                      "td",
                                      {
                                        staticStyle: { "text-align": "right" },
                                        style:
                                          Math.sign(
                                            _vm.dataSummary.betOnFlopCurrent
                                          ) == -1
                                            ? "color: #d42222"
                                            : "color: #000000"
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("currency")(
                                                _vm.dataSummary.betOnFlopCurrent
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "td",
                                  {
                                    staticStyle: { "text-align": "right" },
                                    style:
                                      Math.sign(_vm.dataSummary.parentTotal) ==
                                      -1
                                        ? "color: #d42222"
                                        : "color: #000000"
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("currency")(
                                            _vm.dataSummary.parentTotal
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ])
                            ])
                      ]
                    )
                  ])
                ])
              : _c("div", { staticClass: "col-12" }, [
                  _c("div", { staticClass: "row mt-2" }, [
                    _c("div", { staticClass: "col-sm-6" }, [
                      _c("nav", { attrs: { "aria-label": "breadcrumb" } }, [
                        _c(
                          "ol",
                          { staticClass: "breadcrumb" },
                          _vm._l(_vm.$store.state.reportPages, function(
                            t,
                            index
                          ) {
                            return _c(
                              "li",
                              { key: index, staticClass: "breadcrumb-item" },
                              [
                                index == 0
                                  ? _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          id: "breadcrumb" + index,
                                          to: { name: "WinLose" }
                                        }
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "linkNav bold",
                                            staticStyle: {
                                              "text-decoration": "underline"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.goBack(t.id, t.role)
                                              }
                                            }
                                          },
                                          [_vm._v(" " + _vm._s(t.id) + " ")]
                                        )
                                      ]
                                    )
                                  : index > 0 &&
                                    index <
                                      _vm.$store.state.reportPages.length - 1
                                  ? _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          id: "breadcrumb" + index,
                                          to: {
                                            name: "WinLose",
                                            query: { id: t.id }
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "linkNav bold",
                                            staticStyle: {
                                              "text-decoration": "underline"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.goBack(t.id, t.role)
                                              }
                                            }
                                          },
                                          [_vm._v(" " + _vm._s(t.id) + " ")]
                                        )
                                      ]
                                    )
                                  : _c("a", [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "bold",
                                          staticStyle: {
                                            color: "#cfa137",
                                            "text-decoration": "underline"
                                          },
                                          attrs: { id: "breadcrumb" + index }
                                        },
                                        [_vm._v(" " + _vm._s(t.id) + " ")]
                                      )
                                    ])
                              ],
                              1
                            )
                          }),
                          0
                        )
                      ])
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-sm-6" },
                      [
                        _vm.paginateShow
                          ? _c("paginate", {
                              attrs: {
                                "page-count": _vm.pageSize,
                                "page-range": 3,
                                "prev-text": "<<",
                                "next-text": ">>",
                                "click-handler": _vm.clickCallbackMember,
                                "container-class": "pagination float-right",
                                "prev-class": "inline",
                                "next-class": "inline",
                                "page-class": "inline"
                              },
                              model: {
                                value: _vm.paginate.page,
                                callback: function($$v) {
                                  _vm.$set(_vm.paginate, "page", $$v)
                                },
                                expression: "paginate.page"
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ]),
                  _vm.winLoseData.length == 0
                    ? _c("div", [
                        _c("p", [_vm._v(_vm._s(_vm.$t("DATA_NOT_FOUND")))])
                      ])
                    : _c(
                        "div",
                        { staticClass: "table-responsive tableFixHead" },
                        [
                          _c(
                            "table",
                            { staticClass: "table table-bordered table-hover" },
                            [
                              _c("thead", [
                                _c("tr", [
                                  _c(
                                    "th",
                                    {
                                      staticStyle: {
                                        "text-align": "center",
                                        width: "5%"
                                      },
                                      attrs: { rowspan: "2" }
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("REPORT.NO")) + " "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "th",
                                    {
                                      staticStyle: {
                                        "text-align": "center",
                                        width: "18%"
                                      },
                                      attrs: { rowspan: "2" }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("REPORT.INFORMATION")) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "th",
                                    {
                                      staticStyle: {
                                        "text-align": "center",
                                        width: "16%"
                                      },
                                      attrs: { rowspan: "2" }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("REPORT.EVENT_DETAILS")
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "th",
                                    {
                                      staticStyle: {
                                        "text-align": "center",
                                        width: "2%"
                                      },
                                      attrs: { rowspan: "2" }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("REPORT.BET_RESULT")) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "th",
                                    {
                                      staticStyle: {
                                        "text-align": "center",
                                        width: "16%"
                                      },
                                      attrs: { rowspan: "2" }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("REPORT.LEVEL")) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "th",
                                    {
                                      staticStyle: {
                                        "text-align": "center",
                                        width: "5%"
                                      },
                                      attrs: { rowspan: "2" }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("REPORT.VALID_AMOUNT")
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _vm.summaryControl.validBOF
                                    ? _c(
                                        "th",
                                        {
                                          staticStyle: {
                                            "text-align": "center",
                                            width: "5%"
                                          },
                                          attrs: { rowspan: "2" }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("REPORT.VALID_BOF")
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "th",
                                    {
                                      staticStyle: {
                                        "text-align": "center",
                                        width: "5%"
                                      },
                                      attrs: { rowspan: "2" }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("REPORT.TURN_OVER")) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _vm.summaryControl.validPot
                                    ? _c(
                                        "th",
                                        {
                                          staticStyle: {
                                            "text-align": "center",
                                            width: "5%"
                                          },
                                          attrs: { rowspan: "2" }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("REPORT.VALID_POT")
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "th",
                                    {
                                      staticStyle: {
                                        "text-align": "center",
                                        width: "15%"
                                      },
                                      attrs: {
                                        colspan:
                                          _vm.winLoseData.metadata[0]
                                            .agentPercentGroupReverse.length + 1
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("REPORT.WINLOSE")) +
                                          " "
                                      )
                                    ]
                                  )
                                ]),
                                _c(
                                  "tr",
                                  [
                                    _c("th", { staticClass: "header" }, [
                                      _vm._v(" Type ")
                                    ]),
                                    _vm._l(
                                      _vm.winLoseData.metadata[0]
                                        .agentPercentGroup,
                                      function(ag2) {
                                        return _c(
                                          "th",
                                          {
                                            key: ag2.id,
                                            staticClass: "header"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  ag2.name == "Company"
                                                    ? _vm.$t("REPORT.COMPANY")
                                                    : ag2.name == "Shareholder"
                                                    ? _vm.$t(
                                                        "REPORT.SHAREHOLDER"
                                                      )
                                                    : ag2.name == "Agent"
                                                    ? _vm.$t("REPORT.AGENT")
                                                    : _vm.$t("REPORT.MEMBER")
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      }
                                    )
                                  ],
                                  2
                                )
                              ]),
                              !_vm.dataLoading
                                ? _c("tbody", [_vm._m(1)])
                                : _c(
                                    "tbody",
                                    [
                                      _vm.winLoseData.length == 0
                                        ? [
                                            _c("tr", [
                                              _c(
                                                "td",
                                                { attrs: { colspan: "15" } },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("DATA_NOT_FOUND")
                                                    )
                                                  )
                                                ]
                                              )
                                            ])
                                          ]
                                        : _vm._l(
                                            _vm.winLoseData.metadata,
                                            function(r) {
                                              return _c(
                                                "tr",
                                                {
                                                  key: r.id,
                                                  class: {
                                                    inactive:
                                                      r.status === "INACTIVE"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "td",
                                                    {
                                                      staticStyle: {
                                                        "text-align": "center",
                                                        background: "#f2e8be"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(r.no + 1) +
                                                          " "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticStyle: {
                                                        "text-align": "left",
                                                        background: "#f2e8be"
                                                      }
                                                    },
                                                    [
                                                      _c("div", [
                                                        _c("b", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "REPORT.USER"
                                                              )
                                                            ) + ":"
                                                          )
                                                        ]),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(_vm.userId) +
                                                            " "
                                                        ),
                                                        _c("br"),
                                                        _c("b", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "REPORT.BUY_IN_ID"
                                                              )
                                                            ) + ":"
                                                          )
                                                        ]),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(r.buyInId) +
                                                            " "
                                                        ),
                                                        _c("br"),
                                                        r.type == "TOURNAMENT"
                                                          ? _c("b", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "REPORT.REGISTER_TIME"
                                                                  )
                                                                ) + ":"
                                                              )
                                                            ])
                                                          : _c("b", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "REPORT.START_ROUND"
                                                                  )
                                                                ) + ":"
                                                              )
                                                            ]),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm._f("date")(
                                                                r.startBetTime
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                        _c("br"),
                                                        _c("b", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "REPORT.SETTLE"
                                                              )
                                                            ) + ":"
                                                          )
                                                        ]),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm._f("date")(
                                                                r.updatedDate
                                                              )
                                                            ) +
                                                            " "
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticStyle: {
                                                        "text-align": "left",
                                                        background: "#f2e8be"
                                                      }
                                                    },
                                                    [
                                                      r.type == "TOURNAMENT"
                                                        ? _c("div", [
                                                            _c("b", [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "REPORT.TOURNAMENT_ID"
                                                                    )
                                                                  ) +
                                                                  " :"
                                                              )
                                                            ]),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  r.tournamentId
                                                                ) +
                                                                " "
                                                            ),
                                                            _c("br")
                                                          ])
                                                        : _c("div", [
                                                            _c("b", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "REPORT.HAND_ID"
                                                                  )
                                                                ) + ":"
                                                              )
                                                            ]),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  r.handId
                                                                ) +
                                                                " "
                                                            ),
                                                            _c("br"),
                                                            _c("b", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "REPORT.ROOM_ID"
                                                                  )
                                                                ) + ":"
                                                              )
                                                            ]),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  r.roomId
                                                                ) +
                                                                " "
                                                            )
                                                          ])
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticStyle: {
                                                        "text-align": "center",
                                                        background: "#f2e8be"
                                                      },
                                                      style:
                                                        r.betResult == "WIN"
                                                          ? "color: #0d8c67"
                                                          : r.betResult ==
                                                            "LOSE"
                                                          ? "color: #e84d4d"
                                                          : "color: #2f80ed"
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            r.betResult == "WIN"
                                                              ? _vm.$t(
                                                                  "REPORT.RESULT_STATUS.WIN"
                                                                )
                                                              : r.betResult ==
                                                                "LOSE"
                                                              ? _vm.$t(
                                                                  "REPORT.RESULT_STATUS.LOSE"
                                                                )
                                                              : r.betResult ==
                                                                "DRAW"
                                                              ? _vm.$t(
                                                                  "REPORT.RESULT_STATUS.DRAW"
                                                                )
                                                              : ""
                                                          ) +
                                                          " "
                                                      ),
                                                      _c("br"),
                                                      _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "btn btn-yellow",
                                                          attrs: {
                                                            type: "button"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.openVisualizeReport(
                                                                r.buyInId,
                                                                r.handId
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "fas fa-search"
                                                          }),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "REPORT.BUTTON.DETAIL"
                                                                )
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticStyle: {
                                                        "text-align": "left",
                                                        background: "#f2e8be"
                                                      }
                                                    },
                                                    _vm._l(
                                                      r.agentPercentGroupReverse,
                                                      function(ag, index) {
                                                        return _c(
                                                          "b",
                                                          { key: index },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  ag.name ==
                                                                    "Company"
                                                                    ? _vm.$t(
                                                                        "REPORT.COMPANY"
                                                                      )
                                                                    : ag.name ==
                                                                      "Shareholder"
                                                                    ? _vm.$t(
                                                                        "REPORT.SHAREHOLDER"
                                                                      )
                                                                    : ag.name ==
                                                                      "Agent"
                                                                    ? _vm.$t(
                                                                        "REPORT.AGENT"
                                                                      )
                                                                    : _vm.$t(
                                                                        "REPORT.MEMBER"
                                                                      )
                                                                ) +
                                                                " : " +
                                                                _vm._s(
                                                                  ag.agentPercent
                                                                ) +
                                                                " % "
                                                            ),
                                                            _c("br")
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticStyle: {
                                                        "text-align": "right",
                                                        background: "#f2e8be"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm._f("currency")(
                                                              r.validAmount
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  ),
                                                  _vm.summaryControl.validBOF
                                                    ? _c(
                                                        "td",
                                                        {
                                                          staticStyle: {
                                                            "text-align":
                                                              "right",
                                                            background:
                                                              "#f2e8be"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm._f(
                                                                  "currency"
                                                                )(
                                                                  r.validAmountBOF
                                                                )
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticStyle: {
                                                        "text-align": "right",
                                                        background: "#f2e8be"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm._f("currency")(
                                                              r.validTurnOver
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  ),
                                                  _vm.summaryControl.validPot
                                                    ? _c(
                                                        "td",
                                                        {
                                                          staticStyle: {
                                                            "text-align":
                                                              "right",
                                                            background:
                                                              "#f2e8be"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm._f(
                                                                  "currency"
                                                                )(
                                                                  r.amountWinPots
                                                                )
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticStyle: {
                                                        "text-align": "left"
                                                      }
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(" Win/Lose")
                                                      ]),
                                                      _c("br"),
                                                      _c("span", [
                                                        _vm._v(" Commission")
                                                      ]),
                                                      _c("br"),
                                                      _c("span", [
                                                        _vm._v(" PP ")
                                                      ]),
                                                      _c("br"),
                                                      _vm.summaryControl.bingo
                                                        ? _c("span", [
                                                            _vm._v(" Bingo ")
                                                          ])
                                                        : _vm._e(),
                                                      _vm.summaryControl.bingo
                                                        ? _c("br")
                                                        : _vm._e(),
                                                      _vm.summaryControl.bonus
                                                        ? _c("span", [
                                                            _vm._v(" Bonus ")
                                                          ])
                                                        : _vm._e(),
                                                      _vm.summaryControl.bonus
                                                        ? _c("br")
                                                        : _vm._e(),
                                                      _vm.summaryControl
                                                        .betOnFlop
                                                        ? _c("span", [
                                                            _vm._v(" BoF ")
                                                          ])
                                                        : _vm._e(),
                                                      _vm.summaryControl
                                                        .betOnFlop
                                                        ? _c("br")
                                                        : _vm._e(),
                                                      _c("span", [
                                                        _vm._v(" Total ")
                                                      ])
                                                    ]
                                                  ),
                                                  _vm._l(
                                                    r.agentPercentGroup,
                                                    function(ag3, index) {
                                                      return _c(
                                                        "td",
                                                        {
                                                          key: index,
                                                          staticStyle: {
                                                            "text-align":
                                                              "right"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              style:
                                                                Math.sign(
                                                                  ag3.winloss
                                                                ) == -1
                                                                  ? "color: #d42222"
                                                                  : "color: #000000"
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "currency"
                                                                    )(
                                                                      ag3.winloss
                                                                    )
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          ),
                                                          _c("br"),
                                                          _c(
                                                            "span",
                                                            {
                                                              style:
                                                                Math.sign(
                                                                  ag3.commission
                                                                ) == -1
                                                                  ? "color: #d42222"
                                                                  : "color: #000000"
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "currency"
                                                                    )(
                                                                      ag3.commission
                                                                    )
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          ),
                                                          _c("br"),
                                                          _c(
                                                            "span",
                                                            {
                                                              style:
                                                                Math.sign(
                                                                  ag3.keepPrizePoolAmount
                                                                ) == -1
                                                                  ? "color: #d42222"
                                                                  : "color: #000000"
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "currency"
                                                                    )(
                                                                      ag3.keepPrizePoolAmount
                                                                    )
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          ),
                                                          _c("br"),
                                                          _vm.summaryControl
                                                            .bingo
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  style:
                                                                    Math.sign(
                                                                      ag3.bingoAmount
                                                                    ) == -1
                                                                      ? "color: #d42222"
                                                                      : "color: #000000"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm._f(
                                                                          "currency"
                                                                        )(
                                                                          ag3.bingoAmount
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _vm.summaryControl
                                                            .bingo
                                                            ? _c("br")
                                                            : _vm._e(),
                                                          _vm.summaryControl
                                                            .bonus
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  style:
                                                                    Math.sign(
                                                                      ag3.bonusAmount
                                                                    ) == -1
                                                                      ? "color: #d42222"
                                                                      : "color: #000000"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm._f(
                                                                          "currency"
                                                                        )(
                                                                          ag3.bonusAmount
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _vm.summaryControl
                                                            .bonus
                                                            ? _c("br")
                                                            : _vm._e(),
                                                          _vm.summaryControl
                                                            .betOnFlop
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  style:
                                                                    Math.sign(
                                                                      ag3.betOnFlop
                                                                    ) == -1
                                                                      ? "color: #d42222"
                                                                      : "color: #000000"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm._f(
                                                                          "currency"
                                                                        )(
                                                                          ag3.betOnFlop
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _vm.summaryControl
                                                            .betOnFlop
                                                            ? _c("br")
                                                            : _vm._e(),
                                                          _c(
                                                            "span",
                                                            {
                                                              style:
                                                                Math.sign(
                                                                  ag3.total
                                                                ) == -1
                                                                  ? "color: #d42222"
                                                                  : "color: #000000"
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "currency"
                                                                    )(ag3.total)
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    }
                                                  )
                                                ],
                                                2
                                              )
                                            }
                                          )
                                    ],
                                    2
                                  ),
                              _c("tfoot", [
                                _c(
                                  "tr",
                                  { staticClass: "footer" },
                                  [
                                    _c("td"),
                                    _c(
                                      "td",
                                      {
                                        staticStyle: { "text-align": "right" }
                                      },
                                      [_vm._v("Total :")]
                                    ),
                                    _c("td", {
                                      staticStyle: { "text-align": "right" }
                                    }),
                                    _c("td", {
                                      staticStyle: { "text-align": "right" }
                                    }),
                                    _c("td", {
                                      staticStyle: { "text-align": "right" }
                                    }),
                                    _c(
                                      "td",
                                      {
                                        staticStyle: { "text-align": "right" }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("currency")(
                                                _vm.dataSummary.validAmount
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _vm.summaryControl.validBOF
                                      ? _c(
                                          "td",
                                          {
                                            staticStyle: {
                                              "text-align": "right"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("currency")(
                                                    _vm.dataSummary
                                                      .validAmountBOF
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "td",
                                      {
                                        staticStyle: { "text-align": "right" }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("currency")(
                                                _vm.dataSummary.validTurnOver
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _vm.summaryControl.validPot
                                      ? _c(
                                          "td",
                                          {
                                            staticStyle: {
                                              "text-align": "right"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("currency")(
                                                    _vm.dataSummary
                                                      .amountWinPots
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _c("td", {
                                      staticStyle: { "text-align": "right" }
                                    }),
                                    _vm._l(_vm.totalBetDetail, function(
                                      ag4,
                                      index
                                    ) {
                                      return _c(
                                        "td",
                                        {
                                          key: index,
                                          staticStyle: { "text-align": "right" }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              style:
                                                Math.sign(ag4) == -1
                                                  ? "color: #d42222"
                                                  : "color: #000000"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("currency")(ag4)
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    })
                                  ],
                                  2
                                )
                              ])
                            ]
                          )
                        ]
                      )
                ])
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c(
        "td",
        { staticStyle: { "text-align": "center" }, attrs: { colspan: "12" } },
        [
          _c("div", { staticClass: "d-flex justify-content-center" }, [
            _c(
              "div",
              {
                staticClass: "spinner-border text-primary",
                attrs: { role: "status" }
              },
              [_c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")])]
            )
          ])
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c(
        "td",
        { staticStyle: { "text-align": "center" }, attrs: { colspan: "12" } },
        [
          _c("div", { staticClass: "d-flex justify-content-center" }, [
            _c(
              "div",
              {
                staticClass: "spinner-border text-primary",
                attrs: { role: "status" }
              },
              [_c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")])]
            )
          ])
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }