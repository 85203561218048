



























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Mixins } from "vue-property-decorator";
import WinLoseComponent from "./WinLose.component";

@Component
export default class WinLose extends Mixins(WinLoseComponent) { }
