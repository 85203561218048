import { Component, Vue } from "vue-property-decorator";
import { GET_FILTER_REPORT_BY_ID, GET_BET_DETAIL_BY_ID } from "../../../../api/report"
import DatePicker from 'vue2-datepicker';
import moment from 'moment';
import Model from "@/models/Model";
import VModal from 'vue-js-modal'
import vSelect from 'vue-select';

import 'vue2-datepicker/index.css';
import 'vue-select/dist/vue-select.css';

Vue.use(VModal, { componentName: 'modal' })


@Component({
    components: {
        DatePicker,
        vSelect,
    },
    filters: {
        date(val: any) {
            return val ? moment(val).local().format('DD-MM-YYYY HH:mm:ss') : ''
        },
        currency(value: any) {
            value = isNaN(value) ? 0 : value
            const val = (Math.floor(value * 100) / 100).toFixed(2).replace('.', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
    }
})

class WinLose extends Vue {
    public winLoseData: any = []
    private model: Model;
    private cookieData: any = Vue.$cookies.get("login")

    public dateFilter = {
        startDate: moment().local().startOf('day').toDate(),
        endDate: moment().local().endOf('day').toDate(),
    }

    public columnControlReport = {
        winloss: true,
        commission: true,
        prizepool: true,
        betOnFlop: true,
        total: true,
    }

    getReportColspan(){
        const spanCount = Object.values(this.columnControlReport).filter(value => value).length;
        return spanCount;
    }

    public summaryControl = {
        validBOF: true,
        validPot: true,
        bingo: true,
        betOnFlop: true,
        bonus: true
    }

    public gameTypeChecked = ["ALLIN"]

    private username = ""

    public filterUserId = ""
    public userId = ""
    public roleName = ""
    public roleNameTable = ""

    public paginate = {
        page: 1,
        limit: 50
    }

    public dataSummary = {
        betCount : 0,
        stake : 0,
        validStake : 0,
        realWinlose: 0,
        winlose : 0,
        winloseChild : 0,
        winloseParent : 0,
        winloseGrandParent : 0,
        totalChild : 0,
        totalParent : 0,
        totalGrandParent : 0,
        total : []
    }

    private totalBetDetail = {}
    public pageSize = 0;
    public paginateShow = false;
    public dataLoading = false;

    constructor() {
        super();
        this.model = new Model();
    }

    private loaderStyle = {
        color: '#CFA137',
        loader: 'spinner',
        width: 145,
        height: 235,
        backgroundColor: '#000000',
        opacity: 0.5,
    }
    

    handlePick(date) {
        this.dateFilter.endDate = moment(date).local().endOf('day').toDate()
    }

    async mounted() {
        this.addRouteName()
        this.dataLoading = false
        if (Object.prototype.hasOwnProperty.call(this.cookieData, 'mainUsername')) {
            this.username = this.cookieData.mainUsername
        } else {
            this.username = this.cookieData.username
        }
        this.userId = this.username
        if (Object.prototype.hasOwnProperty.call(this.$route.query, 'id')) {
            this.$router.push({ name: 'WinLose' })
        }
        this.roleName = this.cookieData.roles
        this.$store.commit('resetReport', []);

        await this.getWinLoseDataById(this.userId, this.roleName, this.paginate.page, this.paginate.limit)
        this.dataLoading = true
    }

    private addRouteName(): void {
        this.model.Name = this.$route.name;
        this.$store
            .dispatch("addRouteName", this.model)
    }

    public async selectDate(option: string): Promise<void> {
        switch (option) {
            case 'option1':
                this.dateFilter.startDate = moment(this.dateFilter.startDate).subtract(1, 'days').toDate()
                this.dateFilter.endDate = moment(this.dateFilter.endDate).subtract(1, 'days').toDate()
                break;
            case 'option2':
                this.dateFilter.startDate = moment().local().startOf('day').toDate()
                this.dateFilter.endDate = moment().local().endOf('day').toDate()
                break;
            case 'option3':
                this.dateFilter.startDate = moment().local(true).startOf('day').subtract(1, 'days').toDate()
                this.dateFilter.endDate = moment().local(true).endOf('day').subtract(1, 'days').toDate()
                break;
            case 'option4':
                this.dateFilter.startDate = moment().local(true).startOf('week').toDate()
                this.dateFilter.endDate = moment().local(true).endOf('week').toDate()
                break;
            case 'option5':
                this.dateFilter.startDate = moment().local(true).subtract(1, 'week').startOf('week').toDate()
                this.dateFilter.endDate = moment().local(true).subtract(1, 'week').endOf('week').toDate()
                break;
            case 'option6':
                this.dateFilter.startDate = moment().local(true).startOf('month').toDate()
                this.dateFilter.endDate = moment().local(true).endOf('month').toDate()
                break;
            case 'option7':
                this.dateFilter.startDate = moment().local(true).subtract(1, 'months').startOf('month').toDate()
                this.dateFilter.endDate = moment().local(true).subtract(1, 'months').endOf('month').toDate()
                break;
            case 'option8':
                this.dateFilter.startDate = moment(this.dateFilter.startDate).add(1, 'days').toDate()
                this.dateFilter.endDate = moment(this.dateFilter.endDate).add(1, 'days').toDate()
                break;
            default:
                break;
        }
        if(this.userId.includes("@"))
        {
            this.roleNameTable = 'Member'
            await this.getBetDetail(this.userId, "Member", this.paginate.page, this.paginate.limit)
        } else
        {
            this.roleNameTable = this.roleName
            await this.getWinLoseDataById(this.userId, this.roleName, this.paginate.page, this.paginate.limit)
        }
    }

    public async clickCallback(number: number): Promise<void> {
        const id = this.$route.query.id
        this.paginate.page = number
        if (id) {
            this.roleNameTable = this.roleName
            await this.getWinLoseDataById((id).toString(), this.roleName, this.paginate.page, this.paginate.limit)
        } else {
            this.roleNameTable = this.roleName
            await this.getWinLoseDataById(this.userId, this.roleName, this.paginate.page, this.paginate.limit)
        }
    }

    public async clickCallbackMember(number: number): Promise<void> {
        const id = this.$route.query.id
        this.paginate.page = number
        if (id) {
            this.roleNameTable = 'Member'
            await this.getBetDetail((id).toString(), "Member", this.paginate.page, this.paginate.limit)
        } else {
            this.roleNameTable = 'Member'
            await this.getBetDetail(this.userId, "Member", this.paginate.page, this.paginate.limit)
        }
    }

    public async filterWinLoseData(): Promise<void> {
        this.$store.commit('resetReport', []);
        const id = this.filterUserId
        this.paginate.page = 1
        if(id == "")
        {
            this.userId = this.username
            this.roleName = this.cookieData.roles 
        }
        if(id.includes("@"))
        {
            this.roleNameTable = 'Member'
            this.$store.commit('addReport', { id: this.username, role: this.cookieData.roles }); 
            await this.getBetDetail((id).toString(), "Member", this.paginate.page, this.paginate.limit)
        } else {
            if (id) {
                this.roleNameTable = this.cookieData.roles
                this.$store.commit('addReport', { id: this.username, role: this.cookieData.roles }); 
                await this.getWinLoseDataById((id).toString(), "", this.paginate.page, this.paginate.limit)
            } else {
                this.roleNameTable = this.roleName
                await this.getWinLoseDataById(this.userId, this.roleName, this.paginate.page, this.paginate.limit)
            }
        }
    }

    public goBack(id: string, role: string): void {
        this.dataLoading = false
        this.roleName = role
        const rawReport = this.$store.state.reportPages.findIndex((val: any) => val.id == id)
        const newReport = this.$store.state.reportPages.slice(0, rawReport + 1)
        this.paginate.page = 1
        this.$store.commit("updateReport", newReport)
        this.roleNameTable = this.roleName
        this.getWinLoseDataById(id, this.roleName, this.paginate.page, this.paginate.limit)
        this.dataLoading = true
    }

    public async getReportById(id: string, role: string): Promise<void> {
        this.dataLoading = false
        this.paginate.page = 1;
        this.roleName = role;
        if(id.includes("@")){
            this.roleNameTable = 'Member'
            await this.getBetDetail(id, role, this.paginate.page, this.paginate.limit)
        } else {
            this.roleNameTable = role
            await this.getWinLoseDataById(id, role, this.paginate.page, this.paginate.limit)
        }
        this.dataLoading = true
    }

    private async getBetDetail(user: string, role: string, page: number, limit: number): Promise<void> {
        this.dataLoading = false
        this.winLoseData = []
        const loader = this.$loading.show( this.loaderStyle );
        
        const isALLIN = this.gameTypeChecked.includes("ALLIN")
        const isHOLDEM = this.gameTypeChecked.includes("HOLDEM")

        this.summaryControl.validBOF = false
        this.summaryControl.validPot = false
        this.summaryControl.bingo = false
        this.summaryControl.betOnFlop = false
        this.summaryControl.bonus = false
        if(isALLIN){
            this.summaryControl.validPot = true
            this.summaryControl.bingo = true
            this.summaryControl.bonus = true
        } 
        if(isHOLDEM){
            this.summaryControl.validBOF = true
            this.summaryControl.validPot = true
            this.summaryControl.betOnFlop = true
            this.summaryControl.bonus = true
        }

        const lists = await GET_BET_DETAIL_BY_ID(user, (this.dateFilter.startDate).toISOString(), (this.dateFilter.endDate).toISOString(), JSON.stringify(this.gameTypeChecked), page, limit)
        this.winLoseData = lists.data;

        const report = this.$store.state.reportPages.find((val: any) => val.id == user);

        if (typeof report == 'undefined') {
            this.$store.commit('addReport', { id: user, role: "Member" });
        }

        this.userId = user

        if (this.winLoseData.metadata.length >= 1) {
            const num = (this.paginate.page * this.paginate.limit)
            for (let i = 0, k = num; i < this.winLoseData.metadata.length; i++, k++) {
                this.winLoseData.metadata[i].no = k - this.paginate.limit
                this.winLoseData.metadata[i].agentPercentGroupReverse = [...this.winLoseData.metadata[i].agentPercentGroup].reverse()
            }
            this.paginateShow = true
            this.dataSummary = this.winLoseData.pageInfo[0].dataSummary;
            this.totalBetDetail = this.dataSummary.total
            this.pageSize = this.winLoseData.pageInfo[0].size;
        } else {
            this.winLoseData = []
            this.paginateShow = false
        }

        loader.hide()
        this.dataLoading = true
    }

    private async getWinLoseDataById(user: string, role: string, page: number, limit: number): Promise<void> {
        this.winLoseData = []
        this.dataSummary = {
            betCount : 0,
            stake : 0,
            validStake : 0,
            realWinlose : 0,
            winlose : 0,
            winloseChild : 0,
            winloseParent : 0,
            winloseGrandParent : 0,
            totalChild : 0,
            totalParent : 0,
            totalGrandParent : 0,
            total: []
        }
        const loader = this.$loading.show( this.loaderStyle );
        
        
        const isHOLDEM = this.gameTypeChecked.includes("HOLDEM")

        this.columnControlReport.betOnFlop = false
        if(isHOLDEM) this.columnControlReport.betOnFlop = true
        
            
        const lists = await GET_FILTER_REPORT_BY_ID(user, (this.dateFilter.startDate).toISOString(), (this.dateFilter.endDate).toISOString(), JSON.stringify(this.gameTypeChecked), page, limit)
        this.winLoseData = lists.data;

        const report = this.$store.state.reportPages.find((val: any) => val.id == user);
        if (typeof report == 'undefined' && role !== "") {
            this.$store.commit('addReport', { id: user, role: role });
        } else if (typeof report == 'undefined' && role === "") {
            this.$store.commit('addReport', { id: user, role: this.winLoseData.pageInfo[0].role });
        }

        this.userId = user

        if (this.winLoseData.metadata.length >= 1) {
            const num = (this.paginate.page * this.paginate.limit)
            for (let i = 0, k = num; i < this.winLoseData.metadata.length; i++, k++) {
                this.winLoseData.metadata[i].no = k - this.paginate.limit
            }
            this.paginateShow = true
            this.dataSummary = this.winLoseData.pageInfo[0].dataSummary;
            this.pageSize = this.winLoseData.pageInfo[0].size;
        } else {
            this.winLoseData = []
            this.paginateShow = false
        }

        if(this.roleName == 'Company'){
            this.paginateShow = false
        }
        loader.hide()
    }

    async openVisualizeReport(buyInId: string, handId: string): Promise<void> {
        const handIdParam = handId ? `&handId=${handId}` : ''
        window.open(`${process.env.VUE_APP_API_URL}/game/gameReport/?buyInId=${buyInId}${handIdParam}`, '_blank');
    }

    public hide(field: string): void {
        if (field == 'currency') {
            this.$modal.hide('modalCurrency');
        }
    }
}

export default WinLose